// All the constants used in the app comes here including API call endpoints

// * Common routes and paths
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const MENU_MODE = 'menu_mode';
export const LOGIN = '/login';
export const LOGOUT_ACCESS = '/logout/access';
export const LOGOUT_REFRESH = '/logout/refresh';
export const REFRESH_ACCESS_TOKEN = '/token/refresh';

// * Onboarding Tour Boolean API
export const RUN_ONBOARDING_TOUR = 'to_show_onboarding_tour';

// Excel Download path
export const URL_EXCEL_DOWNLOAD = '/download_file_email';

// Admin Login Selection
export const URL_ADMIN_MERCHANT_CHAIN_LIST = '/admin_merchant_chain_list';

// Send PDPA acceptance log
export const PDPA_ACCEPTANCE_LOG = '/pdpa_tnc';

// * Specific routes and paths
export const URL_CRM_AI_PROMOTION_LVNL_SCORELIST_1 = '/lvsnl_score1';
export const URL_CRM_AI_PROMOTION_LVNL_SCORELIST_2 = '/lvsnl_score2';
export const URL_CRM_AI_PROMOTION_LVNL_SCORETABLE = '/lvsnl_ai';

// ****************************************************
// **** Menu Engineering - Operations Routes/Paths ****
// ****************************************************

export const OPERATIONS_DAILY = '/op_daily';
export const SCORE_CARD = '/score_cards';
export const TABLE_OCCUPANCY = '/table_occupancy';
export const ALL_ORDER_TYPES = '/order_types';

// **************************************************
// **** Menu Engineering - Category Routes/Paths ****
// **************************************************

export const CATEGORY_PENETRATION_CONTRIBUTION =
  '/category_penetration_contribution';
export const MEAL_PENETRATION = '/meal_penetration';
export const CATEGORY_PLACEMENT = '/category_placement';
export const CATEGORY_CONTRIBUTION = '/category_contribution';
export const CATEGORY_BY_TIME = '/category_by_time';

// **********************************************
// **** Menu Engineering - Item Routes/Paths ****
// **********************************************

export const ITEMS_SCORE = '/items_score';
export const ITEM_MAPS = '/item_maps';
export const ITEM_MAPS_QUADRANT = '/quadrant_five';
export const SEARCHED_ITEMS = '/item_searched_name';
export const ITEM_STATS = '/item_stats';
export const ITEMS_BY_TIME = '/item_by_time';
export const ITEM_TRANSACTIONS = '/transactions';
export const ITEM_TRANSACTIONS_SMART_WEB = '/smartweb_transactions';

// *****************************************
// **** Cross-sell up-sell Routes/Paths ****
// *****************************************

export const CONTRIBUTION_CROSS_UP_SELL = '/contribution_cross_up_sell';
export const SALES_COMP = '/sales_comp';
export const CROSS_UP_SALES_SCORE = '/cross_up_score_2';
export const PRICING_CROSS_UP_SELL = '/pricing_cross_up_sell';
export const ITEM_PERFORMANCE_CROSS_UP_SELL = '/item_perfomance_cross_up';

// ****************************************
// **** AI Engine Section Routes/Paths ****
// ****************************************

// ** Login vs Non Login **
export const URL_CRM_AI_ENGINE_LVNL_SCORELIST_1 = '/lvsnl_score1';
export const URL_CRM_AI_ENGINE_LVNL_SCORELIST_2 = '/lvsnl_score2';
export const URL_CRM_AI_ENGINE_LVNL_PERFORMANCE_DOUGHNUTLIST = '/lvsnl_per';
export const URL_CRM_AI_ENGINE_LVNL_CUSTOMER_RETENTION_BARCHARTS =
  '/lvsnl_visits';
export const URL_CRM_AI_ENGINE_LVNL_BARCHART_CUSTOMER = '/lvsnl_cust';
export const URL_CRM_AI_ENGINE_LVNL_BARCHART_SALES = '/lvsnl_sales';
export const URL_CRM_AI_ENGINE_LVNL_BARCHART_ORDERS = '/lvsnl_orders';
export const URL_CRM_AI_ENGINE_LVNL_BARCHART_ABV = '/lvsnl_abv';
export const URL_CRM_AI_ENGINE_LVNL_SCORETABLE = '/lvsnl_ai';

// ** New vs Repeat **
export const URL_CRM_AI_ENGINE_NVR_SCORELIST_1 = '/nvsr_score1';
export const URL_CRM_AI_ENGINE_NVR_SCORELIST_2 = '/nvsr_score2';
export const URL_CRM_AI_ENGINE_NVR_PERFORMANCE_DOUGHNUTLIST = '/nvsr_per';
export const URL_CRM_AI_ENGINE_NVR_CUSTOMER_RETENTION_BARCHARTS =
  '/nvsr_visits';
export const URL_CRM_AI_ENGINE_NVR_BARCHART_CUSTOMER = '/nvsr_cust';
export const URL_CRM_AI_ENGINE_NVR_BARCHART_SALES = '/nvsr_sales';
export const URL_CRM_AI_ENGINE_NVR_BARCHART_ORDERS = '/nvsr_orders';
export const URL_CRM_AI_ENGINE_NVR_BARCHART_ABV = '/nvsr_abv';
export const URL_CRM_AI_ENGINE_NVR_SCORETABLE = '/nvsr_ai';

// *******************************************
// **** AI Promotion Section Routes/Paths ****
// *******************************************

export const URL_CRM_AI_PROMOTION_SCORELIST = '/pr_score';
export const URL_CRM_AI_PROMOTION_SC_N_PD = '/pr_segment';

// ********************************************
// **** Customer Segmentation Routes/Paths ****
// ********************************************

export const URL_CRM_CUSTOMER_SEGMENT_GA_SCORELIST = '/ga_score';
export const URL_CRM_CUSTOMER_SEGMENT_GA_BARCHART_CUSTOMER = '/ga_cust';
export const URL_CRM_CUSTOMER_SEGMENT_GA_BARCHART_SALES = '/ga_sales';
export const URL_CRM_CUSTOMER_SEGMENT_GA_BARCHART_ORDERS = '/ga_orders';
export const URL_CRM_CUSTOMER_SEGMENT_GA_BARCHART_ABV = '/ga_abv';
export const URL_CRM_CUSTOMER_SEGMENT_GA_BARCHART_GCPP = '/ga_gcpp';
export const URL_CRM_CUSTOMER_SEGMENT_GA_TOB_TABLE = '/ga_top_ordered';
export const URL_CRM_CUSTOMER_SEGMENT_GA_PGC_HEATMAP =
  '/ga_percent_group_contri';

// ** Customer Analysis **

export const URL_CRM_CUSTOMER_SEGMENT_CA_TABLE = '/customer_analysis';

// ** Segment Analysis **

export const URL_CRM_CUSTOMER_SEGMENT_SA_TOP_ORDER_TABLE = '/cm_top_ordered';
export const URL_CUSTOMER_MAP = '/customer_map';

// ** Tracker Page **

export const URL_TRACKER_SCORE_CARDS = '/getTrackerScoreCard';
export const URL_TRACKER_DETAILS = '/getAllTrackerDetails';
export const URL_TRACKER_SECTION_LIST = '/getList';
export const URL_TRACKER_SECTION_DETAILS = '/getDetails';
export const URL_ADD_TRACKER = '/addTracker';
export const URL_TRACKER_METRICS = '/cat_metrics';
export const URL_SUB_CAT_TRACKER_METRICS = '/sub_cat_metrics';
export const URL_TRACKER_ITEM_METRICS = '/item_tracker';
export const URL_TRACKER_ITEM_CSUS_METRICS = '/item_recom_tracker';
export const URL_TRACKER_CATEGORY_BY_TIME = '/category_time_tracker';
export const URL_TRACKER_SUB_CAT_BY_TIME = '/sub_category_time_tracker';
export const URL_TRACKER_ITEM_BY_TIME = '/item_time_tracker';
export const URL_ITEM_SALES_ORDERS_TRACKER = '/item_sales_orders_tracker';
export const URL_SUB_CAT_SALES_ORDERS_TRACKER =
  '/sub_category_sales_orders_tracker';
export const URL_CATEGORY_SALES_ORDERS_TRACKER =
  '/category_sales_orders_tracker';
export const URL_STOP_TRACKER = '/update_tracker_end_date';
export const URL_TRACKER_DETAILS_MERGE = '/getTrackerDetails';

// ** Live Data Page **

export const LIVE_DATA = '/live_data';

export const SC_LIVE_SCORES = '/smart_connect/live_scores';
export const SC_LIVE_DROPDOWNS = '/smart_connect/live_dropdown_list';
export const SC_LIVE_TABLE = '/smart_connect/live_table';

// ** OrderType Analysis Page **

export const ORDER_TYPE_DATA = '/order_type';

// ** Live Data Page **

export const URL_FETCH_OUTLET_INFO = '/outlet_info';
export const URL_UPDATE_OUTLET_INFO = '/updateOutletInfo';
export const URL_FETCH_TIME_RANGES = '/getTimeRanges';
export const URL_SAVE_TIME_RANGES = '/saveTimeRange';
export const URL_DELETE_TIME_RANGES = '/DeleteTimeRanges';
export const URL_FETCH_ITEM_DETAILS = '/itemDetails';
export const URL_UPDATE_ITEM_DETAILS = '/updateItem';
export const URL_FETCH_CUISINES = '/get_cusines';
export const URL_OUTLET_INFO_EXCEL_FORMAT = '/itemDetailsExcel';
export const URL_OUTLET_INFO_FROM_EXCEL = '/itemDetailsExcelUpload';
export const URL_FETCH_COMMISSION_DETAILS =
  '/master_merchant/commission_info_list';
export const URL_SAVE_COMMISSION_DETAILS = '/master_merchant/add_commission';
export const URL_DELETE_COMMISSION_DETAILS =
  '/master_merchant/delete_commission_delete';

// ** Chain Info Page **

export const URL_FETCH_ZONES = '/chain_info_zone_list';
export const URL_FETCH_LOCATIONS = '/create_chain_zone_locations';
export const URL_CREATE_ZONE = '/chain_info_create_zone';
export const URL_EDIT_ZONE = '/chain_info_edit_zone';
export const URL_DELETE_ZONE = '/chain_info_delete_zone';

// ** Payment Reconciliation Page **

export const URL_PAYMENT_SCORES = '/payment_scores';
export const URL_PAYOUT_SCORES = '/payout_scores';
export const URL_PAYOUT_CHANNELS = '/payout_channel';
export const URL_SETTLEMENTS = '/partners_settlements';
export const URL_PAYOUT_ORDERS = '/payout_orders';
export const URL_PAYOUT_CHANNEL_DETAILS = '/payment_channel';
export const URL_PAYOUTS = '/payout_details';
export const URL_POS_UNCAPTURED = '/pos_uncaptured_transac';
export const URL_MANUALLY_SETTLE = '/ops_team_inform_transac';
export const URL_PAYMENT_DETAIL_MERGE = '/payment_details';
export const URL_RECON_PDF = '/reconciliation_report/pdf_download';
export const URL_PAYOUT_DETAIL_MERGE = '/new_payout_details';

// ** Tip Remittance Page **

export const URL_TIP_SCORES = '/tipping_scores';
export const URL_TIP_PAYMENT_DETAIL = '/tipping_table';
export const URL_TIP_DETAILS_BREAKUP = '/staff_table';

// ** Terminal Analytics **

export const URL_TERMINAL_ANALYTICS_SCORE = '/smart_terminal/scores';
export const URL_TERMINAL_ANALYTICS_DAILY_SUMMARY =
  '/smart_terminal/daily_summary';
export const URL_TERMINAL_ANALYTICS_TRANSACTIONS_ORDERS =
  '/smart_terminal/trans_orders';
export const URL_TERMINAL_ANALYTICS_DROPDOWNS = '/smart_terminal/dropdowns';

// ** Benchmarking **

export const URL_FETCH_ZONE_LIST = '/drop_down_zone_location';
export const URL_FETCH_OVERVIEW_MAP_LIST = '/overview_map';
export const URL_FETCH_OVERVIEW_SCORE = '/overview_score_card';
export const URL_FETCH_OVERVIEW_CONTRIBUTION = '/contributions';
export const URL_FETCH_OVERVIEW_GROWTH_RATE = '/growth_rate';
export const URL_FETCH_ENTERPRISE_STATS = '/overview_statistics';

export const URL_FETCH_POSITION_MAP = '/position_map';

export const URL_FETCH_PERFORMANCE_ANALYSIS =
  '/zone_location_performance_analysis';
export const URL_FETCH_PERFORMANCE_SCORE = '/performance_analysis_score_card';
export const URL_FETCH_MEAL_PENETRATION = '/meal_penetration_ib';
export const URL_FETCH_PRICE_BAND_ANALYSIS = '/price_band_analysis';
export const URL_FETCH_PRICE_BAND_STATS = '/price_band_statistics';
export const URL_FETCH_ORDER_TYPE_PERFORMANCE = '/order_type_data';
export const URL_FETCH_ORDER_ITEM_PLOT = '/order_item_plot';
export const URL_FETCH_BUCKET_LIST = '/list_buckets_time_of_the_day';
export const URL_FETCH_TIME_BASED_PERFORMANCE = '/time_based_performance';
export const URL_FETCH_CAPACITY_UTILIZATION = '/capacity_utilization';
export const URL_FETCH_CUSTOMER_PROFILING_SCORES = '/score_card_login_nonlogin';
export const URL_FETCH_CF_NVSR_SCORES = '/score_card_new_repeat';
export const URL_FETCH_CUSTOMER_PROFILING_ORDER_TYPES = '/list_order_type';
export const URL_FETCH_CUSTOMER_PROFILING_LVSNL = '/login_non_login_compare';
export const URL_FETCH_CUSTOMER_PROFILING_NVSR = '/new_repeat_compare_plot';
export const URL_FETCH_CUSTOMER_PROFILING_REPEAT_RATE =
  '/repeat_customer_contribution';
export const URL_FETCH_MENU_PROFILING_ITEMS = '/items_ib';
export const URL_FETCH_MENU_PROFILING_CATEGORY = '/category_ib';

// ** Smart Connect **
export const URL_MM_SCORES = '/master_merchant/scores';
export const URL_MM_DROPDOWN_LIST = '/master_merchant/dropdown_list';
export const URL_MM_PERFORMANCE = '/master_merchant/performance';
export const URL_MM_TREND = '/master_merchant/trend';
export const URL_MM_PROFIT_PERCENTAGE = '/master_merchant/profit_percentage';
export const URL_MM_PROFIT_TREND = '/master_merchant/profit_trend';

export const URL_SC_CHAIN_SCORES = '/smart_connect_chain/chain_scores';
export const URL_SC_CHAIN_PERFORMANCE =
  '/smart_connect_chain/chain_performance';
export const URL_SC_CHAIN_DROPDOWN_LIST = '/smart_connect_chain/dropdown_list';
export const URL_SC_CHAIN_ZL_PERFORMANCE = '/smart_connect_chain/zone_loc_perf';
export const URL_SC_CHAIN_TREND = '/smart_connect_chain/trend';

// export const URL_SC_CANCELLED_ORDERS_SCORES_OUTLET =
//   '/cancelled_orders/score_outlet';
// export const URL_SC_CANCELLED_ORDERS_TABLE_OUTLET =
//   '/cancelled_orders/table_outlet';
// export const URL_SC_CANCELLED_ORDERS_BREAKUP_OUTLET =
//   '/cancelled_orders/breakup_outlet';
// export const URL_SC_CANCELLED_ORDERS_REASONS_OUTLET =
//   '/cancelled_orders/reasons_outlet';
// export const URL_SC_CANCELLED_ORDERS_SCORES_CHAIN =
//   '/cancelled_orders/score_chain';
// export const URL_SC_CANCELLED_ORDERS_TABLE_CHAIN =
//   '/cancelled_orders/table_chain';
// export const URL_SC_CANCELLED_ORDERS_BREAKUP_CHAIN =
//   '/cancelled_orders/breakup_chain';
// export const URL_SC_CANCELLED_ORDERS_REASONS_CHAIN =
//   '/cancelled_orders/reasons_chain';
export const URL_SC_CANCELLED_ORDERS_CHAIN_MERGE = '/cancelled_orders/chain_cancelled_details'
export const URL_SC_CANCELLED_ORDERS_OUTLET_MERGE = '/cancelled_orders/outlet_cancelled_details'

// ** Promotion Usage **
export const URL_PU_SCORES = '/promotion_usage/scores';
export const URL_PU_TABLE = '/promotion_usage/table';
export const URL_PU_PROMO_NON_PROMO = '/promotion_usage/promo_vs_non_promo';
export const URL_PU_BY_DAY_TIME = '/promotion_usage/by_day_time';
export const URL_PU_CODE_USAGE = '/promotion_usage/code_usage';
export const URL_PU_DROPDOWNS = '/promotion_usage/dropdowns';

// ** Day End Report **
export const URL_DER_DROPDOWNS = '/dayend_report/dropdowns';
export const URL_DER_SUMMARY_SCORES = '/dayend_report/scores';
export const URL_DER_SUMMARY_TABLE = '/dayend_report/daily_summary';
export const URL_DER_ITEM_TABLE = '/dayend_report/items';
export const URL_DER_CATEGORY_TABLE = '/dayend_report/category';
export const URL_DER_ITEM_TRANSACTIONS_TABLE = '/dayend_report/trans_items';
export const URL_DER_TRANSACTIONS_TABLE = '/dayend_report/trans_orders';
export const URL_DER_PDF = '/dayend_report/pdf_download';
export const URL_SWEDEN_GST_REPORT = '/swe_gst_report/pdf_download';
export const URL_DER_VOID_SCORES = '/dayend_report/scores_void_orders';
export const URL_DER_VOID_BREAKUP = '/void_orders/breakup';
export const URL_DER_VOID_TREND = '/dayend_report/trend_void_orders';
export const URL_DER_VOID_TABLE = '/dayend_report/trans_void_orders';

export const URL_POS_SUMMARY_SCORES = '/pos/scores';
export const URL_POS_SUMMARY_TABLE = '/pos/daily_summary';
export const URL_POS_ITEM_TABLE = '/pos/pos_items';
export const URL_POS_CATEGORY_TABLE = '/pos/pos_category';
export const URL_POS_TRANSACTIONS_ITEMS_TABLE = '/pos/trans_items';
export const URL_POS_TRANSACTIONS_ORDERS_TABLE = '/pos/trans_orders';
export const URL_POS_DROPDOWN_LIST = '/dayend_report/dropdowns';

// ** Lost Revenue
export const URL_LR_SCORE = '/lost_revenue/scores';
export const URL_LR_CONTRIBUTION = '/lost_revenue/contriution';
export const URL_LR_TABLE_OP = '/lost_revenue/details_order_pause';
export const URL_LR_TABLE_SO = '/lost_revenue/details_stock_in_out';
export const URL_LR_TREND = '/lost_revenue/trend';
export const URL_LR_CI = '/lost_revenue/item_category_wise';
export const URL_LR_OUTLETWISE = '/lost_revenue/outletwise';
export const URL_LR_LIVE_DATA_OUTLETWISE = '/lost_revenue/live_outletwise';
export const URL_LR_LIVE_DATA_SCORES = '/lost_revenue/live_score';
export const URL_LR_LIVE_DATA_STOCKOUT_TABLE =
  '/lost_revenue/live_stockout_table';
export const URL_LR_LIVE_DATA_ORDER_PAUSE_TABLE =
  '/lost_revenue/live_pause_table';

// ** Feedback & email **
export const URL_SHOW_FEEDBACK_POPUP = '/show_feedback_popup';
export const URL_SHOW_EMAIL_POPUP = '/show_email_popup';
export const URL_SAVE_FEEDBACK = '/save_feedback';
export const URL_SAVE_EMAIL = '/save_email';
export const URL_SHOW_EMAILS = '/get_email';
export const URL_DELETE_EMAILS = '/delete_email';
export const URL_UPDATE_EMAILS = '/update_email';

// ** Help Section **
export const URL_HELP_SECTION = '/help_video';

// ** Paywall Message Notification**
export const URL_PAYWALL = '/paywallmessagenotified';